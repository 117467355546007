import { useHistory } from "react-router";
import { Dropdown, Menu, Modal } from "antd";
import { CloseOutlined, EllipsisOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useState } from "react";
import QRCode from "qrcode.react";

const GameMenu = (props) => {
  const history = useHistory();
  const { sendJsonMessage, host, code } = props;
  const [isQrModalVisible, setIsQrModalVisible] = useState(false);

  const handleMenuClick = (event) => {
    switch (event.key) {
      case 'end':
        sendJsonMessage({
          action: "end"
        });
        break;
      case 'exit':
        history.replace("/");
        break;
      case 'qr':
        showQrModal()
        break;
    }
  }

  const showQrModal = () => {
    setIsQrModalVisible(true);
  };

  const handleCancel = () => {
    setIsQrModalVisible(false);
  };

  const menuItem = host ? (
    <Menu.Item key="end" icon={<CloseOutlined />}>
      End Game
    </Menu.Item>
  ) : (
    <Menu.Item key="exit" icon={<CloseOutlined />}>
      Exit Game
    </Menu.Item>
  )

  const menu = (
    <Menu onClick={handleMenuClick} size="large">
      <Menu.Item key="qr" icon={<QrcodeOutlined />}>
        Show QR Code
      </Menu.Item>
      <Menu.Divider />
      {menuItem}
    </Menu>
  );


  return (
    <>
      <Dropdown type="text" size="large" trigger={['click']} overlay={menu}>
        <a id="Game-menu" onClick={e => e.preventDefault()}>
          <EllipsisOutlined />
        </a>
      </Dropdown>
      <Modal title="Scan this!" centered visible={isQrModalVisible} footer={null} onCancel={handleCancel}>
        <div className="Game-qr-modal">
          <p>{code}</p>
          <QRCode
            renderAs="svg"
            level="L"
            size={256}
            value={`https://${window.location.host}/${code}`}
          />
        </div>
      </Modal>
    </>
  )
}

export default GameMenu;