import { Button } from "antd";
import { useState, useEffect } from "react";
import { reduce, map, max, random, isEmpty, find } from "lodash-es";

import BeerFill from "../components/BeerFill";
import GameMenu from "../components/GameMenu";
import RateQuestion from "../components/RateQuestion";
import QuestionText from "../components/QuestionText";
import TimeoutCountdown from "../components/TimeoutCountdown";


import "./Results.scss";
import { multiply } from "lodash-es";
import { divide } from "lodash-es";
import { floor } from "lodash-es";

const Result = (props) => {
  const {
    sendJsonMessage,
    questionData,
    answerTimeout,
    resultData,
    currentPlayerId,
    players,
    host,
    code,
    timestamp
  } = props;

  const images = [
    "/solo_1.png",
    "/solo_2.png",
    "/solo_3.png",
    "/solo_4.png",
    "/solo_5.png",
  ];

  const reacts = {
    winners: [
      '😜',
      '👑',
      '🏆',
      '🙌',
      '🥇',
      '💪',
      '😎'
    ],
    losers: [
      '😭',
      '😤',
      '😫',
      '😖',
      '🤬',
      '😮‍💨',
      '🥺'
    ]
  }
  const [imageNumber] = useState(random(0, images.length - 1, false));
  const [reactNumber] = useState(random(0, reacts.winners.length - 1, false));

  const startingHeight = players.length > 1 ? ((players.length - 1) / players.length) * 100 : 100;
  const [beerFillHeight, setBeerFillHeight] = useState(startingHeight);

  useEffect(() => {
    setTimeout(() => {
      setBeerFillHeight(100);
      setTimeout(() => setBeerFillHeight(0), 1100)
    }, 100)
  }, [])

  const getColorForPlayer = (id) => {
    return id === currentPlayerId ? "#ff4d4f" : "#1890ff";
  }

  let result = "";
  let image = null;
  switch (resultData.type) {
    case "all":
      image = "/all_drink.png";
      result = <p>Everyone take a sip!</p>;

      break;
    case "both":
      const [one, two] = resultData.winners;
      image = "/both_drink.png";
      result = (
        <p>
          <span style={{ color: getColorForPlayer(one.id) }}>{one.name}</span> and{" "}
          <span style={{ color: getColorForPlayer(two.id) }}>{two.name}</span> both take a sip!
        </p>
      );

      break;
    case "outright":
      image = images[imageNumber];
      result = (
        <p>
          Take a sip{" "}
          <span style={{ color: getColorForPlayer(resultData.winner.id) }}>{resultData.winner.name}</span>!
        </p>
      );

      break;
    case "decider":
      result = (
        <>
          <p>Decider!</p>
          <img className="Game-gloves" src="/boxing_gloves.png" alt="Boxing gloves." />
          <p>
            {reduce(
              map(resultData.winners, ({ id, name }) =>
                <span style={{ color: getColorForPlayer(id) }}>{name}</span>),
              (sum, n) => !sum ? n : <>{sum} vs {n}</>,
            )}
          </p>
        </>
      );

      break;
    case "decision":
      image = images[imageNumber];
      result = (
        <p>
          Bottoms up{" "}
          <span style={{ color: getColorForPlayer(resultData.winner.id) }}>{resultData.winner.name}</span>!
        </p>
      );

      break;
    case "no_decision":
      image = "/all_drink.png";
      result = (
        <>
          <p>Can't decide?</p>
          <p>Everyone take a sip!</p>
        </>
      );

      break;
    case "least_rather":
    case "no_rather":
      const colors = [
        '#ff4d4f',
        '#1890ff',
        '#8BC34A'
      ]
      const totalAnswers = reduce(resultData.answers, (sum, answerTotal) => sum + answerTotal)
      const optionTotals = map(questionData.options, (option, id) => {
        const answerTotal = resultData.answers[id] ?? 0
        return {
          id,
          option: option,
          total: floor(divide(multiply(answerTotal, 100), totalAnswers))
        }
      })

      const resultText = isEmpty(resultData.winners)
        ? 'Even! Everyone drink!'
        : find(resultData.winners, { id: currentPlayerId })
          ? `You're safe!`
          : `Take a sip!`

      const highestTotal = max(map(optionTotals, 'total'))

      result = (
        <>
          {map(optionTotals, ({ id, option, total }) =>
            <div key={id} className={`Result-option ${total === highestTotal ? `winner` : `loser`}`} style={{ backgroundColor: colors[id] }}>
              <p className="option-icon">
                {total === highestTotal
                  ? reacts.winners[reactNumber]
                  : reacts.losers[reactNumber]}
              </p>
              <p className="option-title">{option}</p>
              <p>{total}%</p>
            </div>
          )}
          <p style={{ marginTop: '1em' }}>
            {resultText}
          </p>
        </>
      );

      break;
  }

  const handleSubmit = () => {
    sendJsonMessage({
      action: "next"
    });
  };

  return (
    <div className="Game Result-header">
      <div className="large-timeout">
        <TimeoutCountdown value={timestamp + (answerTimeout * 1000)} />
      </div>
      <BeerFill height={beerFillHeight} />
      <TimeoutCountdown value={timestamp + (answerTimeout * 1000)} />
      <GameMenu sendJsonMessage={sendJsonMessage} host={host} code={code} />
      {image && <img className="Result-image" src={image} alt="" />}
      <div id="image-float">
        <p className="Result-question-text">
          {!isEmpty(questionData) &&
            <QuestionText questionData={questionData} players={players} currentPlayerId={currentPlayerId} />
          }
        </p>

        {result}
        {host &&
          <Button
            onClick={handleSubmit}
            type="default"
            size="large">
            Next.
          </Button>
        }
      </div>
      {random(0, 5, false) > 4 &&
        <RateQuestion questionData={questionData} />
      }
    </div>
  );
}

export default Result;