import { Button, Input, message } from "antd";
import { useState, useEffect } from "react";

const PickName = (props) => {
  const { sendJsonMessage, error } = props;
  const [name, setName] = useState("");

  useEffect(() => {
    if (error) {
      message.error(error)
    }
  }, [error])

  const handleSubmit = (event) => {
    event.preventDefault();
    sendJsonMessage({
      action: "name",
      data: { name },
    });
  };

  return (
    <div className="Game Name-header">
      <span className="header">Pick a name!</span>
      <form onSubmit={handleSubmit}>
        <p>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your name."
            size="large"
            required={true}
          />
        </p>
        <Button type="primary" htmlType="submit" size="large">
          Let's Go!
        </Button>
      </form>
    </div>
  );
}

export default PickName;