import { Button } from "antd";
import { FacebookFilled, InstagramFilled } from '@ant-design/icons';

import "./App.scss";

const facebookURL = 'https://www.facebook.com/calledoutapp/';
const instagramURL = 'https://www.instagram.com/calledout.app/';

const App = (props) => {
  return (
    <div className="App">
      <header className="App-header">
        <img className="logo" src="/solo_2.png" alt="Hand holding a beer."/>
        <span className={`Main-header header`}>CalledOut!</span>
        <Button className="menu-button" type="danger" htmlType="submit" size="large" onClick={() => props.history.push(`/create`)}>
          Create Game!
        </Button>
        <span>- Or -</span>
        <Button className="menu-button" type="primary" htmlType="submit" size="large" onClick={() => props.history.push(`/join`)}>
          Join Game!
        </Button>
        <div className="social-media-buttons">
          <FacebookFilled onClick={() => window.open(facebookURL, '_blank')} />
          <InstagramFilled onClick={() => window.open(instagramURL, '_blank')} />
        </div>
      </header>
    </div>
  );
}

export default App;
