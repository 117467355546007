import { Button, Spin } from "antd";
import { filter } from "lodash-es";
import QRCode from "qrcode.react";

const Lobby = (props) => {
  const { players, host, code, sendJsonMessage } = props;
  const readyPlayers = filter(players, (player) => player.name);

  const handleSubmit = (event) => {
    event.preventDefault();
    sendJsonMessage({
      action: "start"
    });
  };

  return (
    <div className="Game Game-header">
      <span className="plain-header">{code}</span>
      <QRCode
        bgColor="#673ab7"
        fgColor="white"
        renderAs="svg"
        level="L"
        size={256}
        value={`https://play.calledout.app/${code}`}
      />

      <p>👆 Scan this to join! 👆</p>
      <Spin size="large" />
      <p>{`${readyPlayers.length}/${players.length}`} players ready.</p>
      {host &&
        <form onSubmit={handleSubmit}>
          <Button type="primary" htmlType="submit" size="large">
            Start!
          </Button>
        </form>
      }
    </div>
  );
}

export default Lobby;