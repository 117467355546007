import { Button, Space } from "antd";
import { map } from "lodash-es";

const PlayerButtons = (props) => {
  const {
    players,
    onClick,
    currentPlayerId
  } = props;

  const buttons = map(players, (player, index) => (
    <Button
      type={player.id === currentPlayerId ? "danger" : "primary"}
      onClick={() => onClick(player.id)}
      key={index}
      size="large"
    >
      {player.name}
    </Button>
  ));

  return (
    <Space direction="vertical">
      {buttons}
    </Space>
  )
}

export default PlayerButtons;