import { Button } from "antd";
import { filter } from "lodash-es";

import GameMenu from "../components/GameMenu";
import PlayerButtons from "../components/PlayerButtons";
import OptionButtons from "../components/OptionButtons";
import QuestionText from "../components/QuestionText";
import TimeoutCountdown from "../components/TimeoutCountdown";
import RoundCountdown from "../components/RoundCountdown";

import "./Question.scss";

const Question = (props) => {
  const {
    currentPlayerId,
    questionData,
    questionTimeout,
    mode,
    rounds,
    players,
    sendJsonMessage,
    setAnswered,
    host,
    code,
    timestamp
  } = props;

  const handleClick = (playerId) => {
    sendJsonMessage({
      action: "answer",
      data: { answer: playerId },
    });
    setAnswered();
  }

  const getBackgroundColor = () => {
    switch (questionData.deck) {
      case "mild":
        return "#8BC34A";
      case "spicy":
        return "#FF9800";
      case "extra_hot":
        return "#F44336";
      default:
        return "#673ab7";
    }
  }

  const getQuestionTextColor = () => {
    switch (questionData.deck) {
      case "mild":
      case "spicy":
      case "extra_hot":
        return "rgba(0, 0, 0, 0.65)";
      default:
        return "white";
    }
  }

  const handleSkip = () => {
    sendJsonMessage({
      action: "skip"
    });
  };

  let buttons = null
  if (
    questionData.type === "decider" ||
    questionData.type === "duel" ||
    questionData.type === "threeway"
  ) {
    buttons = <PlayerButtons
      players={questionData.players}
      onClick={handleClick}
      currentPlayerId={currentPlayerId} />
  } else if (questionData.type === "general") {
    const readyPlayers = filter(players, ({ name }) => name)
    buttons = <PlayerButtons
      players={readyPlayers}
      onClick={handleClick}
      currentPlayerId={currentPlayerId} />
  } else if (questionData.type === "would_you_rather") {
    buttons = <OptionButtons
      options={questionData.options}
      onClick={handleClick} />
  }

  return (
    <div className="Game" style={{ backgroundColor: getBackgroundColor() }}>
      <TimeoutCountdown value={timestamp + (questionTimeout * 1000)} />
      <RoundCountdown currentRound={questionData.currentRound} rounds={rounds}/>
      <GameMenu sendJsonMessage={sendJsonMessage} host={host} code={code} />
      {questionData.type === "duel" && <p className="Game-mode-header">Head-to-Head!</p>}
      {questionData.type === "threeway" && <p className="Game-mode-header">Threeway!</p>}
      {(mode === 'standard' || (host && mode === 'mc')) &&
        <p className="question-text" style={{ color: getQuestionTextColor() }}>
          <QuestionText questionData={questionData} players={players} currentPlayerId={currentPlayerId} />
        </p>
      }
      {buttons}
      {host &&
        <Button
          onClick={handleSkip}
          type="default"
          size="large"
          style={{ marginTop: "2rem" }}>
          Skip.
        </Button>
      }

    </div>
  );
}

export default Question;