import { clone, find } from "lodash-es";
import reactStringReplace from "react-string-replace";

const QuestionText = (props) => {
  const {
    questionData,
    players,
    currentPlayerId
  } = props;

  let questionText = questionData.text;

  if (questionData.playerId) {
    let player = find(players, ["id", questionData.playerId]);

    if (!player) {
      player = {
        id: 'none',
        name: 'Unknown'
      }
    }

    questionText = reactStringReplace(questionData.text, new RegExp('(%s)', 'g'), (match, index, offset) =>
      <span
        style={{ color: player.id === currentPlayerId ? "#ff4d4f" : "#1890ff" }}
        className="player-name"
        key={`pl-${index}`}>
        {player.name}
      </span>
    );
  }

  if (questionData.options) {
    let colors = [
      '#ff4d4f',
      '#1890ff',
      '#8BC34A'
    ]

    const options = clone(questionData.options)
    questionText = reactStringReplace(questionText, new RegExp('(%o)', 'g'), (match, index, offset) =>
      <span
        style={{ color: colors.shift() }}
        className="player-name"
        key={`op-${index}`}>
        {options.shift()}
      </span>
    );
  }

  return questionText;
}

export default QuestionText;