import { useHistory } from "react-router";
import { Button } from "antd";

const Error = (props) => {
  const { errorData } = props;
  const history = useHistory();

  const handleClick = () => {
    history.replace("/");
  }

  return (
    <div className="Game Game-header">
      <p className="Game-mode-header">{errorData?.message || `An error occurred.`}</p>
      <Button size="large" onClick={handleClick}>
        Ok.
      </Button>
    </div>
  );
}

export default Error;