import { Button } from "antd";
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { useState } from "react";

const RateQuestion = (props) => {
  const { questionData } = props;
  const [questionRated, setQuestionRated] = useState(false);

  const handleRateQuestion = (event) => {
    setQuestionRated(true);
  
    const url = 'https://6aebcrp6ce.execute-api.ap-southeast-2.amazonaws.com/calledout/rate';
    const body = {
      questionId: questionData.id
    }
  
    if (event.target.id === 'like') {
      body.like = true
    } else {
      body.dislike = true
    }
  
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then((response) => console.log(response))
      .catch((error) => console.error(error))
  }

  let content;
  if (questionRated) {
    content = "Thanks for your feedback!";
  } else if (questionData.id) {
    content = (
      <>
        <Button id="dislike" type="danger" shape="circle" onClick={handleRateQuestion} icon={<DislikeOutlined />} ghost />
        &nbsp;Rate this question.&nbsp;
        <Button id="like" type="primary" shape="circle" onClick={handleRateQuestion} icon={<LikeOutlined />} />
      </>
    );
  }
  return (
    <div className="question-vote">
      {content}
    </div>
  );
}

export default RateQuestion;