import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './BackButton.scss'

const BackButton = () => {
    const history = useHistory();

    return <ArrowLeftOutlined onClick={() => history.goBack()} className="Back-button" />
}

export default BackButton;