import { Button, Spin } from "antd";
import { filter } from "lodash-es";
import BeerFill from "../components/BeerFill";
import TimeoutCountdown from "../components/TimeoutCountdown";

import "./Waiting.scss";

const Waiting = (props) => {
  const {
    players,
    answers,
    host,
    sendJsonMessage,
    questionTimeout,
    timestamp
  } = props;

  const readyPlayers = filter(players, ({ name }) => name);

  const handleSubmit = (event) => {
    event.preventDefault();
    sendJsonMessage({
      action: "skip"
    });
  };

  const calculatePercent = () => {
    return (answers.length / readyPlayers.length) * 100;
  }

  return (
    <div className="Game Game-header" style={{ zIndex: 2 }}>
      <BeerFill height={calculatePercent()} />
      <div style={{ zIndex: 20 }}>
        <TimeoutCountdown value={timestamp + (questionTimeout * 1000)} />
        <Spin size="large" />
        <p id="answered-count">{`${answers.length}/${readyPlayers.length}`} players answered.</p>
        {host &&
          <form onSubmit={handleSubmit}>
            <Button type="default" htmlType="submit" size="large">
              Skip.
            </Button>
          </form>
        }
      </div>
    </div>
  );
}

export default Waiting;