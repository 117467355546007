import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.min.css";
import App from "./App";
import Create from "./Create";
import Join from "./Join";
import Game from "./Game";
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "@fontsource/lobster";
import "@fontsource/carter-one"

Sentry.init({
  dsn: "https://bb2f1cb5ce8842418e900471df4b740a@o398895.ingest.sentry.io/6140571"
});

if (process.env.REACT_APP_TARGET === 'app') {
  console.log('Env Var Works')
}

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/create" component={Create} />
      <Route path="/join" component={Join} />
      <Route path="/host" component={Game} />
      <Route path="/:roomId" component={Game} />
      <Route path="/" component={App} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
