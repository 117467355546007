import { Spin } from "antd";

const Loading = () => {
  return (
    <div className="Game Game-header">
      <Spin size="large" />
      <p>Loading...</p>
    </div>
  );
}

export default Loading;