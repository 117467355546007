import { List } from "antd";
import BackButton from "./components/BackButton";

import "./Create.scss";

const Create = (props) => {
  const decks = [
    { id: "mild", text: "Keepin' it casual.", color: "#8BC34A", icon: "😎" },
    { id: "spicy", text: "Let's spice things up!", color: "#FF9800", icon: "🌶️" },
    { id: "extra_hot", text: "That's f*cked up.", color: "#F44336", icon: "🙈" },
    // { id: "robot", text: "CalledOut AI (beta)", color: "#4A99B5", icon: "🤖" }
    // { id: "covid", text: "COVID coping.", color: "#4ECEE4", icon: "😷" },
    // { id: "dog", text: "What the dog doin'?", color: "#c48c60", icon: "🐶" },
    // { id: "aussie", text: "Australia Day", color: "#3477eb", icon: "🇦🇺" }
  ]

  return (
    <div className="App">
      <header className="Create-header">
        <BackButton />
        <h3 className="modal-header">
          Select your heat!
        </h3>
        <form style={{ textAlign: "center" }}>
          <List
            split={false}
            dataSource={decks}
            renderItem={item => (
              <List.Item style={{ padding: "5px 0px" }}>
                <button
                  onClick={() => props.history.push(`/host?decks[]=${item.id}`)}
                  id={item.id}
                  className="deck-checkbox"
                  style={{ background: item.color }}
                  type="text">
                  <span className="deck-title">{item.text}</span>
                  <span className="deck-icon">{item.icon}</span>
                </button>
              </List.Item>
            )}
          />
        </form>
      </header>
    </div>
  );
}

export default Create;
