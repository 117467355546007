import { useState } from "react";
import { Input, Button, message } from "antd";
import BackButton from "./components/BackButton";

import "./Join.scss";

const App = (props) => {
  const [roomCode, setRoomCode] = useState("");

  const handleChangeRoomCode = (event) => {
    setRoomCode(event.target.value)
  }

  const handleJoin = (event) => {
    event.preventDefault();
    if (roomCode && roomCode.length === 4) {
      props.history.push(`/${roomCode}`);
    } else {
      message.error('Please enter a code.', 2)
    }
  }

  return (
    <div className="App">
      <header className="Join-header">
        <BackButton />
        <h3 className="modal-header">
          Enter room code.
        </h3>
        <form onSubmit={handleJoin}>
          <p>
            <Input
              placeholder="6 Character Room Code"
              size="large"
              value={roomCode}
              onChange={handleChangeRoomCode}
              style={{ borderRadius: "10px" }}
            />
          </p>
          <Button
            id="join-button"
            className="modal-button"
            type="danger"
            htmlType="submit"
            size="large">
            Join Game!
          </Button>
        </form>
      </header>
    </div>
  );
}

export default App;
