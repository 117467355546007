import useWebSocket, { ReadyState } from "react-use-websocket";
import { useParams, useLocation } from "react-router";
import { useMemo, useRef } from "react";
import { findLast, isEmpty } from "lodash-es";

import End from "./views/End";
import Error from "./views/Error";
import Loading from "./views/Loading";
import Lobby from "./views/Lobby";
import PickName from "./views/PickName";
import Question from "./views/Question";
import Result from "./views/Result";
import Waiting from "./views/Waiting";

import "./Game.scss";

// const socketUrl = "ws://localhost:80";
const socketUrl = "wss://api.calledout.app:443";

const Game = (props) => {
  const location = useLocation();
  const { roomId } = useParams();
  const messageHistory = useRef([]);
  const latestEvent = useRef({});
  const gameInfo = useRef({});
  const answers = useRef([]);

  const searchParams = new URLSearchParams(location.search);

  const createInfo = {
    decks: searchParams.getAll('decks[]'),
  }

  if (searchParams.get('mode') && searchParams.get('rounds')) {
    createInfo.mode = searchParams.get('mode');
    createInfo.rounds = searchParams.get('rounds');
  }

  const {
    sendJsonMessage,
    lastJsonMessage,
    readyState,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log("Web socket opened."),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  messageHistory.current = useMemo(
    () => messageHistory.current.concat(lastJsonMessage),
    [lastJsonMessage]
  );

  answers.current = useMemo(() => {
    if (lastJsonMessage && lastJsonMessage.action === "answers") {
      answers.current = lastJsonMessage.data.answers;
    }
    return answers.current;
  }, [lastJsonMessage]);

  gameInfo.current = useMemo(() => {
    if (lastJsonMessage && (lastJsonMessage.action === "info")) {
      return lastJsonMessage.data;
    }

    return gameInfo.current;
  }, [lastJsonMessage]);

  latestEvent.current = useMemo(() => {
    if (lastJsonMessage) {
      if (
        lastJsonMessage.action !== "info" &&
        lastJsonMessage.action !== "answers"
      ) {
        return lastJsonMessage;
      }
    }
    return latestEvent.current;
  }, [lastJsonMessage]);

  const setAnswered = () => {
    latestEvent.current = {
      action: "answered",
    };
  };

  const previousQuestion = useMemo(() => {
    return findLast(messageHistory.current,
      (message) => message && message.action === 'question'
    );
  }, [messageHistory.current]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  if (latestEvent.current) {
    switch (latestEvent.current.action) {
      case "connected":
        if (!isEmpty(gameInfo.current)) {
          sendJsonMessage({
            action: "rejoin",
            data: { id: gameInfo.current.id, code: gameInfo.current.code },
          });
        } else if (createInfo.decks.length) {
          sendJsonMessage({
            action: "create",
            data: createInfo,
          });
        } else if (roomId) {
          sendJsonMessage({
            action: "join",
            data: { code: roomId.toUpperCase() },
          });
        } else {
          return <Error errorData={latestEvent.current.data} />
        }
        break;

      case "started":
        sendJsonMessage({ action: "ready" });
        break;

      case "created":
      case "joined":
      case "failed_name":
        return (
          <PickName
            sendJsonMessage={sendJsonMessage}
            error={latestEvent.current.action === `failed_name` ? latestEvent.current.data.message : null}
          />
        );

      case "named":
        return <Lobby
          players={gameInfo.current.players}
          host={gameInfo.current.host}
          code={gameInfo.current.code}
          sendJsonMessage={sendJsonMessage}
        />;

      case "answered":
        return <Waiting
          players={gameInfo.current.players}
          answers={answers.current}
          host={gameInfo.current.host}
          sendJsonMessage={sendJsonMessage}
          questionTimeout={gameInfo.current.game.questionTimeout}
          timestamp={previousQuestion ? previousQuestion.ts : + new Date()}
        />;

      case "question":
        return (
          <Question
            currentPlayerId={gameInfo.current ? gameInfo.current.id : null}
            sendJsonMessage={sendJsonMessage}
            setAnswered={setAnswered}
            questionData={latestEvent.current.data}
            questionTimeout={gameInfo.current.game.questionTimeout}
            mode={gameInfo.current.game.mode}
            rounds={gameInfo.current.game.rounds}
            players={gameInfo.current.players}
            host={gameInfo.current.host}
            code={gameInfo.current.code}
            timestamp={latestEvent.current.ts}
          />
        );

      case "result":
        return (
          <Result
            sendJsonMessage={sendJsonMessage}
            currentPlayerId={gameInfo.current ? gameInfo.current.id : null}
            resultData={latestEvent.current.data}
            questionData={previousQuestion ? previousQuestion.data : {}}
            answerTimeout={gameInfo.current.game.answerTimeout}
            players={gameInfo.current.players}
            host={gameInfo.current.host}
            code={gameInfo.current.code}
            timestamp={latestEvent.current.ts}
          />
        );

      case "failed_create":
      case "failed_join":
      case "failed_rejoin":
        return <Error errorData={latestEvent.current.data} />

      case "ended":
        return (
          <End
            endData={latestEvent.current.data}
            currentPlayerId={gameInfo.current ? gameInfo.current.id : null}
          />
        );

      case "rejoined":
      default:
        return Loading();
    }
  }

  return Loading();
}

export default Game;
