import confetti from "canvas-confetti";
import { useHistory } from "react-router";
import { Button } from "antd";
import { map, orderBy } from "lodash-es";

const End = (props) => {
  const { endData, currentPlayerId } = props;
  const history = useHistory();

  const getColorForPlayer = (id) => {
    return id === currentPlayerId ? "#ff4d4f" : "#1890ff";
  }

  const handleClick = () => {
    history.replace("/");
  }

  const players = map(
    orderBy(endData.winners, ['count'], ['desc']),
    player => (
      <p>
        <span className="player-name" style={{ color: getColorForPlayer(player.id) }}>{player.name}</span> had {player.count} {player.count > 1 ? "drinks" : "drink"}.
      </p>
    )
  );

  confetti({
    origin: {
      x: 0.5,
      y: 0.9
    },
    gravity: 0.7,
    ticks: 400,
    disableForReducedMotion: true,
    particleCount: 80
  });

  return (
    <div className="Game Game-header">
      <p className="Game-mode-header">Game over.</p>
      {endData.winners.length > 0 && players}
      <Button size="large" onClick={handleClick}>
        Ok.
      </Button>
    </div>
  );
}

  export default End;