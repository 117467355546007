import { floor, random, times } from "lodash-es";
import "./BeerFill.scss";

const BeerFill = (props) => {
  const { height } = props;

  const getBubbles = () => {
    const bubbleCount = floor(window.innerWidth / 30);

    return times(bubbleCount, (n) => ({
      n: n * 30,
      delay: random(0, 700, false),
      duration: random(1300, 2000, false)
    }));
  }

  return (
    <div id="liquid" style={{ height: `${height}%` }}>
      <div id="foam" style={{ background: `url('${"/foam.png"}')` }}></div>
      {getBubbles().map(({ n, delay, duration }) => <div key={n} className="bubble"
        style={{ left: `${n}px`, animationDelay: `${delay}ms`, animationDuration: `${duration}ms` }}
      />)}
    </div>
  );
}

export default BeerFill