import { Button, Space } from "antd";
import { map } from "lodash-es";

const OptionButtons = (props) => {
  const {
    options,
    onClick
  } = props;

  const colors = [
    '#ff4d4f',
    '#1890ff',
    '#8BC34A'
  ]

  const buttons = map(options, (option, index) => (
    <Button
      style={{ backgroundColor: colors[index], borderColor: colors[index] }}
      type="primary"
      onClick={() => onClick(index)}
      key={index}
      size="large"
    >
      {option}
    </Button>
  ));

  return (
    <Space direction="vertical">
      {buttons}
    </Space>
  )
}

export default OptionButtons;